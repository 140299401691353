import i,{css as e}from"styled-components";import{typography as o,color as t}from"../../../shared/styles.js";const r="ZUIInput",n=i.div`
  position: relative;
  margin: 8px 0;
  font-family: ${o.type.primary};
  color: ${t.dark};
  outline: none;

  ${i=>"big"===i.size&&"width: 280px;"}
  ${i=>"medium"===i.size&&"width: 343px;"}
  ${i=>"normal"===i.size&&"width: 220px;"}
  ${i=>i.fitParent&&"width: 100%;"}

  label {
    display: block;
    margin: 0 0 6px;
    width: fit-content;
    color: ${({colorOnFocus:i})=>i};

    &.${r}--fit-parent {
      width: 100%;
    }
    ${({disabled:i})=>i&&e`
        color: ${t.medium};
      `}
  }

  input {
    ${({disabled:i})=>i&&e`
        color: ${t.medium};
      `}
  }

  .${r}__icon-search {
    ${({disabled:i})=>i&&e`
        img {
          cursor: not-allowed;
          filter: brightness(0) saturate(100%) invert(75%) sepia(8%)
            saturate(5%) hue-rotate(325deg) brightness(95%) contrast(90%);
        }
      `}
  }

  .${r} {
    &__hint-container {
      display: flex;
      align-items: center;
      margin-top: 5px;
      position: relative;

      &_optional-message {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 9;
        font-family: ${o.type.primary};
        font-style: normal;
        font-weight: ${o.weight.regular};
        font-size: ${o.size.s1};
        line-height: 14px;
        text-align: right;
        color: ${t.blueDark};
      }

      &_hint {
        font-family: ${o.type.primary};
        font-style: normal;
        font-weight: ${o.weight.regular};
        font-size: ${o.size.s1};
        line-height: 14px;
        color: ${t.mediumDark};
      }

      &_error {
        position: absolute;
        top: 0;
        left: 0;
        width: 190px;
        z-index: 9;
        font-family: ${o.type.primary};
        font-style: normal;
        font-weight: ${o.weight.regular};
        font-size: ${o.size.s1};
        line-height: 14px;
        text-align: left;
        color: ${t.red};
        box-sizing: border-box;
        background-color: #ffffff;
      }
    }

    &__loader {
      position: absolute;
      height: 100%;
      width: 24px;
      display: flex;
      align-items: center;
      right: 0;
      margin-right: 16px;
    }
  }
`,a=i.div`
  display: flex;
  flex-direction: ${i=>i.hasIcon&&"left"===i.iconPosition?"row-reverse":"row"};
  width: 100%;
  padding-right: 0;
  background-color: ${({disabled:i})=>i?t.mediumLight:"#ffffff"};
  border: 1px solid
    ${({hasError:i})=>i?t.red:t.mediumLight};
  border-radius: 4px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;

  ${i=>"big"===i.size&&"min-height: 72px;"}
  ${i=>"medium"===i.size&&"min-height: 56px;"}
  ${i=>"normal"===i.size&&"min-height: 46px;"}


  &:focus-within {
    border: 2px solid ${({hasError:i})=>i?t.red:t.blueDark};
  }

  input {
    ${i=>i.hasIcon&&"right"===i.iconPosition?"padding-right: 0;":""}

    ${i=>i.hasIcon&&"left"===i.iconPosition?"padding-left: 0;":""}

    ${i=>"big"===i.size&&"font-size: 24px; padding: 0 32px;"}

    ${i=>"medium"===i.size&&"font-size: 24px; padding: 0 32px;"}

    ${i=>"zip"===i.inputType&&"normal"!==i.size&&"letter-spacing: 24px; text-indent: 12px;"}

    text-align: ${i=>i.textAlign};
  }

  &.${r}--invalid {
    color: ${t.red};
    border-color: ${t.red};

    &.zactive {
      border-width: 2px;
    }
  }

  .${r}__icon-search, .${r}__show_password {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-width: 20px;
    padding: ${i=>"left"===i.iconPosition?"0 8px 0 15px":"0 15px 0 8px"};
    height: initial;
    cursor: pointer;

    img {
      width: 20px;
    }
  }

  .${r}__show_password {
    justify-content: center;
    flex-shrink: 0;
    font-size: ${o.size.s1};
  }

  .${r}__is-ready {
    display: flex;
    width: ${i=>"big"===i.size?"22px":"15px"};
    padding: 5px;

    img {
      width: 100%;
    }
  }
`,d=i.input`
  width: 100%;
  min-width: 0;
  height: auto;
  padding: 0 15px;
  font-size: ${o.size.s3};
  font-family: ${o.type.primary};
  color: ${({disabled:i})=>i?t.medium:t.dark};
  background-color: #ffffff;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: right 15px bottom 13px;
  border: none;
  transition: all 0.3s ease-in-out;
  -webkit-appearance: none;
  box-sizing: border-box;
  outline: none;

  /* Disable type=number arrows */
  &[type='number'] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
  }

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  ::placeholder {
    color: ${t.mediumDark};
    ${({disabled:i})=>i&&e`
        color: ${t.medium};
      `}
  }

  &:focus {
    outline: none;
  }

  &.${r}--loading {
    background-image: radial-gradient(
      ${t.bluePrimary} 15%,
      ${t.bluePrimary} 16%
    );
    background-size: 0% 2px;
    background-position: 0 100%;
    background-repeat: no-repeat;
    -webkit-animation: animatedBackground 0.8s linear infinite alternate;
    animation: animatedBackground 0.8s linear infinite alternate;
  }

  @keyframes animatedBackground {
    0% {
      background-position: 0 100%;
      background-size: 0% 2px;
    }

    50% {
      background-position: 0 100%;
      background-size: 100% 2px;
    }

    51% {
      background-position: 100% 100%;
      background-size: 100% 2px;
    }

    100% {
      background-position: 100% 100%;
      background-size: 0% 2px;
    }
  }

  ${({disabled:i})=>i&&e`
      background: ${t.mediumLight};
      cursor: not-allowed;
    `}
`,s=i.div`
  position: absolute;
  width: 24px;
  height: 24px;
  animation: spCircRot 0.8s infinite linear;
  box-sizing: border-box;
  border: 2px ${t.dark} solid;
  border-top: 2px rgba(255, 255, 255, 1) solid;
  border-radius: 50%;

  &.blue {
    border-color: ${t.bluePrimary};
    border-top: 2px rgba(255, 255, 255, 1) solid;
  }

  &.gray {
    border-color: ${t.medium};
    border-top: 2px rgba(255, 255, 255, 1) solid;
  }

  @-webkit-keyframes spCircRot {
    from {
      -webkit-transform: rotate(0deg);
    }

    to {
      -webkit-transform: rotate(359deg);
    }
  }

  @keyframes spCircRot {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(359deg);
    }
  }
`;export{r as COMP_NAME,n as Container,a as InputWrapper,s as Loader,d as StyledInput};

import e from"styled-components";import{color as t,typography as i}from"./styles.js";const o=e.label`
  align-items: center;
  color: ${t.darker};
  display: flex;
  flex-direction: row;
  font-family: ${i.type.primary};
  font-size: ${i.size.s3};
  font-style: normal;
  font-weight: normal;
  justify-content: flex-start;
  line-height: 17px;

  &.visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
`;export{o as default};

import r,{css as o}from"styled-components";import{typography as e,color as i,filter as t}from"../../../shared/styles.js";const a="ZUIButton",d={primary:"filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(302deg) brightness(107%) contrast(105%);","red-primary":"filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(302deg) brightness(107%) contrast(105%);",secondary:`filter: ${t.bluePrimary};`,tertiary:`filter: ${t.dark};`,ghost:`filter: ${t.bluePrimary};`,alert:`filter: ${t.red};`,green:"filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(302deg) brightness(107%) contrast(105%);"},n=`filter: ${t.medium};`,l=r.button.attrs({type:"button"})`
  /* Base */

  display: flex;
  position: relative;
  flex-direction: ${r=>"right"===r.iconPosition?"row":"row-reverse"};
  justify-content: center;
  align-items: center;
  position: relative;
  width: fit-content;
  min-width: max-content;
  height: fit-content;
  padding: 11.5px 13.5px;
  color: #ffffff;
  text-align: center;
  font-family: ${e.type.primary};
  font-weight: 400;
  border: 2px solid ${i.bluePrimary};
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;

  &:focus {
    outline: none;

    &::before {
      position: absolute;
      width: 100%;
      height: 100%;
      padding: 4px;
      content: '';
      border: 2px solid ${i.blueLight};
      border-radius: 4px;
    }
  }

  .${a}__icon {
    max-width: 17px;
    max-height: 17px;
    ${({colorIcon:r,colorType:o,disabled:e})=>r?e?n:d[o]:""}
    ${r=>r.iconOnly?"":"right"===r.iconPosition?"margin-left: 15px;":"margin-right: 15px;"}
  }

  /* Types */

  &.${a}--primary {
    background-color: ${i.bluePrimary};

    &:hover {
      border-color: ${i.blueDark};
      background-color: ${i.blueDark};
    }

    &:active {
      border-color: ${i.blueDarker};
      background-color: ${i.blueDarker};
    }
  }

  &.${a}--red-primary {
    background-color: ${i.red};
    border-color: ${i.red};

    &:hover {
      border-color: #eb6a6a;
      background-color: #eb6a6a;
    }

    &:active {
      border-color: #d36564;
      background-color: #d36564;
    }

    &:focus::before {
      border: 2px solid ${i.red};
    }
  }

  &.${a}--green {
    background-color: ${i.green};
    border-color: ${i.green};

    &:hover {
      border-color: ${i.greenDark};
      background-color: ${i.greenDark};
    }

    &:active {
      border-color: ${i.greenDarker};
      background-color: ${i.greenDarker};
    }

    &:focus::before {
      border: 2px solid ${i.greenLighter};
      border-radius: 4px;
    }
  }

  &.${a}--alert {
    color: ${i.red};
    background-color: #ffffff;
    border-color: ${i.red};

    &:hover {
      border-color: ${i.red};
      background-color: ${i.redLight};
    }

    &:active {
      border-color: ${i.red};
      background-color: ${i.redDark};
    }

    &:focus::before {
      border: 2px solid ${i.redDark};
      border-radius: 4px;
    }
  }

  &.${a}--secondary {
    color: ${i.bluePrimary};
    border-color: transparent;
    background-color: transparent;

    &:hover {
      background-color: ${i.blueLighter};
    }

    &:active {
      background-color: ${i.blueLight};
      color: ${i.blueDark};
    }

    &:focus::before {
      border: 2px solid ${i.bluePrimary};
      width: 100%;
      height: 100%;
      padding: 0;
      border-radius: 4px;
    }
  }

  &.${a}--ghost, &.${a}--tertiary {
    &:hover {
      background-color: ${i.blueLighter};
    }

    &:active {
      background-color: ${i.blueLight};
      color: ${i.blueDark};
    }
  }

  &.${a}--ghost {
    color: ${i.bluePrimary};
    background-color: white;
  }

  &.${a}--tertiary {
    color: ${i.dark};
    background-color: transparent;
    border-color: transparent;

    &:hover {
      border-color: ${i.blueLighter};
      color: ${i.dark};
    }

    &:active {
      border-color: ${i.blueLight};
      color: ${i.dark};
    }

    &:focus::before {
      border: 1px solid ${i.blueLight};
      width: 100%;
      height: 100%;
      padding: 0;
      border-radius: 4px;
    }
  }

  &.${a}--time {
    color: ${i.mediumDark};
    font-weight: ${e.weight.regular};
    background-color: white;
    border-color: transparent;
    padding: 4px 8px;
    border-radius: 4px;

    &:hover {
      background-color: ${i.blueLighter};
    }

    &:active {
      background-color: ${i.blueLight};
    }

    &:focus {
      color: ${i.bluePrimary};
    }

    &:focus::before {
      border: 1px solid ${i.bluePrimary};
      width: 100%;
      height: 100%;
      padding: 0px;
      border-radius: 4px !important;
    }

    ${({active:r})=>r&&o`
        background-color: ${i.blueLighter};
        border: 1px solid ${i.bluePrimary};
        color: ${i.bluePrimary};
      `}

    ${({responsive:r,active:t})=>r&&o`
        @media screen and (max-width: ${r}px) {
          min-width: 113px;
          color: #ffffff;
          background: ${i.bluePrimary};
          font-size: ${e.size.s3};
          line-height: 19px;

          ${t&&o`
            background-color: ${i.blueLighter};
            border: 1px solid ${i.bluePrimary};
            color: ${i.bluePrimary};
          `}
        }
      `}
  }

  &.${a}--disabled-time {
    cursor: auto;
    color: ${i.medium};
    border-color: transparent;
    background-color: white;
    pointer-events: none;
  }

  &.${a}--disabled, &.${a}--disabled-ghost {
    background-color: ${i.mediumLight};
    border-color: ${i.mediumLight};
    color: ${i.medium};
    cursor: auto;
    pointer-events: none;

    &:focus::before {
      content: unset;
    }
  }

  &.${a}--disabled-ghost {
    border-color: ${i.medium};
  }

  /* Loading */

  &.${a}--loading {
    color: transparent;
    cursor: auto;
    pointer-events: none;

    img {
      visibility: hidden;
    }
  }

  /* Sizes */

  &.${a}--small {
    padding-top: 5.5px;
    padding-bottom: 5.5px;
    font-size: ${e.size.s2};

    &:focus::before {
      border-width: 1px;
      border-radius: 4px;
    }
  }

  &.${a}--medium {
    font-size: ${e.size.s3};
  }

  &.${a}--large {
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 18px;
  }

  &.${a}--fit-parent {
    width: 100%;
  }

  /* Warning icon/message */

  .${a}__warning_icon {
    position: absolute;
    top: -6px;
    right: -6px;
    width: 21px;
    height: 21px;
    border-radius: 50%;
    cursor: pointer;

    &_bg {
      position: absolute;
      top: -5px;
      right: -5px;
      width: 19px;
      height: 19px;
      border-radius: 50%;
      background-color: #ffffff;
    }
  }

  .${a}__warning_message {
    position: absolute;
    bottom: 60px;
    right: -85px;
    width: 170px;
    padding: 4px 16px;
    font-size: ${e.size.s2};
    text-align: center;
    color: ${i.red};
    background-color: ${i.redLight};
    box-sizing: border-box;

    &::before {
      content: '';
      position: absolute;
      bottom: -6px;
      right: 74px;
      width: 0;
      height: 0;
      border-left: 16px solid transparent;
      border-right: 16px solid transparent;
      border-top: 12px solid ${i.redLight};
    }
  }
`,b=r.div`
  position: absolute;
  top: calc(50% - 28px);
  left: calc(50% - 8px);
  width: 13px;
  height: 13px;
  border: 2px rgba(255, 255, 255, 0.3) solid;
  border-top: 2px rgba(255, 255, 255, 1) solid;
  clear: both;
  margin: 20px auto;
  border-radius: 50%;
  -webkit-animation: spCircRot 0.6s infinite linear;
  animation: spCircRot 0.8s infinite linear;
  box-sizing: content-box;

  &.${a}--blue-loader {
    border: 2px rgba(92, 123, 235, 0.3) solid;
    border-top: 2px rgba(92, 123, 235, 1) solid;
  }
  &.${a}--gray-loader {
    border: 2px rgba(180, 180, 180, 0.3) solid;
    border-top: 2px rgba(180, 180, 180, 1) solid;
  }
  &.${a}--lightgray-loader {
    border: 2px rgba(220, 220, 220, 0.3) solid;
    border-top: 2px ${i.mediumLight} solid;
  }

  @-webkit-keyframes spCircRot {
    from {
      -webkit-transform: rotate(0deg);
    }

    to {
      -webkit-transform: rotate(359deg);
    }
  }

  @keyframes spCircRot {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(359deg);
    }
  }
`;export{l as ButtonContainer,b as ButtonLoader,a as COMP_NAME};
